table{
  border-collapse: collapse;
  margin: 20px auto;
  width: 1200px;
}
td, th{
  padding:10px;
  border: 1px solid black;
  text-align: center;
}
.board_footer{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap:0px 10px;
}
.board_footer a{
  cursor: pointer;
} 