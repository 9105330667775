header{
  min-width: 1200px;
  margin: 20px auto;
}

nav > ul{
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  gap: 0px 30px;
  padding-left: 50px;
}

nav > ul > li > a:link, nav > ul > li > a:visited{
  font-size: 1.2em;
  font-style: normal;
  color: #333;
  text-decoration: none;
}
nav > ul > li > a:hover{
  color: #f00;
  font-weight: bold;
}