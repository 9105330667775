.edu_container{
    min-width: 1200px;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
}
.edu_container > section{
    display: flex;
    justify-content: space-around;
    gap: 0px 30px;
    padding: 0px 50px;
}
.edu_container > hr{
    margin-top: 20px;
    margin-bottom: 20px;
}
.edu_container > section > div{
    min-height: 300px;
    border-radius: 10px;
    box-sizing: border-box;
}
.result{
  width: 60%;
}
#map{
    width: 40%;
}
.edu_container > section table{
    width: 100%;
    border-collapse: collapse;
}
.edu_container > section th, .edu_container > section td{
    padding: 10px;
    border: 1px solid #333;
}
.edu_container > section td{
    font-size: 0.9em;
}
