.container{
  width: 90vh;
  margin: 30px auto;
}
.title{
  font-weight: bold;
}
.row{
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  gap: 10px;
}
.comment{
  font-size: 0.8rem;
  width: 90vh;
  margin: 10px auto;

}
.comment-section{
  margin-top: 10px;
}
.comment-row{
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  gap: 10px;
}

.comment-section > .input{
  width: 85%;
  height: 70px;
  box-sizing: border-box;
}
.comment-section > .btn_add_comment{
  width: 15%;
  height: 70px;
  box-sizing: border-box;
}